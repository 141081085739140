//
// Local navigation
// Focus: custom
// --------------------------------------------------

.cb-local-navigation {
  &.cb-local-navigation-split {
    .cb-glyph {
      font-size: @font-size-base;
      line-height: 1.5em;
      margin:0;
      padding: 4px 0 4px 0;
    }

    ul:not(.cb-menu-list-ul) {
      li {
        margin: 0;
        a {
          font-size: 0.875em;
          display: block;
          color: @black-corporate-color;
          height: 1.5em;
          padding: 0;
          margin: 0
        }
      }
    }
  
    // Desktop Layout
    .cb-desktop-navigation {
      width: 100%;
      .col-xs-12 {
        height: 71px; 
      }
      .cb-site-name {
        margin-right: 48px;
        height: 99%;
        padding-top: 36px;
        display: inline-block;
        a.title {
          .roboto-slab-bold();
          font-size: 1.4375rem;
          line-height: 1.0434782608695652em;
          padding: 0;
          color: @black-corporate-color;
          margin: 0;
          
        }
        &.cb-multi {
          padding-top: 36px;
          a.title {
            max-width: 100%;
            span {
              word-break: normal;
            }
          }
        }
        &.hover {
          background-color: transparent;
          span {
            padding-bottom: 7px;
            border-bottom: 2px solid @black-corporate-color;
          }
        }
      }
      :not(.cb-menu-list-ul) {
        li {
          padding:36px 24px 0 24px;
          a {
            margin-right: 0;
            margin-left: 0;
            font-size: 0.875rem;
            line-height: 1.7142857142857142em; //24
            line-height: 2em; //28
            height: 36px;
          }
          &.active,
          &.active-trail {
            & > a {
              border-bottom: 2px solid @black-corporate-color;
            }
          }
          // TBD
          &.cb-multi {
            & > a {
                line-height: 18px;
              span {
                max-width: 105px;
                float: left;
                margin-top: -15px;
              }
            }
          }
    
          &.cb-multi {
            &.cb-icon {
              & > a {
                span {
                  padding-left: 18px;
                }
              }
              .glyphicon-cb,
              .cb-glyph {
                float: left;
                position: relative;
                &::before{
                  top: 5px;
                  position: absolute;
                  left: 0px;
                }
              }
            }
          }
  
          &.focus,
          &.hover,
          &:focus,
          &:hover  {
            a {
              border-bottom: 2px solid @black-corporate-color;
            }
            background: transparent;
          }
          &.dropdown {
            .dropdown-toggle {
              padding-right: 24px;
              margin-right: 0;
              .cb-glyph{
                padding: 0;
                &::before {
                  font-size: 1rem;
                  bottom: -6px;
                  right: -20px;
                }
              }
            }
          }
        }
      }
      .dropdown-menu li{
        margin-bottom: 1px;
      }

      .cb-local-menu{
        &.cb-promotional {
          float: left;
          li {
            &:nth-of-type(1) {
              padding-left: 0;
            }
          }
        }
        &.cb-procedural {
          float: right;
          li {
            &:nth-last-of-type(1),
            &.last-item,
            &.exp-more{
              padding-right: 0;
            }
          }
        }
      }

      margin: 0 auto;
    }
  
    @media (max-width: @screen-sm-max) {
      border-bottom: 0;
      height: 48px;
    }
    // Mobile Layout
    .cb-mobile-navigation {
      li {
        border-bottom: 1px solid @cb-gray7-color;
        a {
          font-size: 1rem !important;
          line-height: 1.5em;
          min-height: 47px;
          border-bottom: 0!important;
        }
        &.dropdown:after,
        &.mobile-dropdown:after{
            right: 0!important;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
  
      .cb-mobile-local-header {
        height: 48px;
        padding: 0;
        padding: 11px 24px 24px;
        padding-bottom: 0;
        margin: 0;
        a{
          &.local-menu-title {
            color: @black-corporate-color;
            .roboto-slab-bold();
            font-size: 1.4375rem;
            line-height: 1.0434782608695652em;
          }
        }
        .local-menu-icon {
          color: @black-corporate-color;
          font-size: 1em;
          position: absolute;
          top: 12px;
          right: 24px;
          .cb-glyph{
            margin-left: 0;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      // TBD
      &.cb-multi {
        .cb-mobile-local-header {
          padding-top: 4px;
        }
        a{
          &.local-menu-title {
            font-size: 0.875em;
            line-height: 1.286em;
            max-width: 120px;
            display: block;
            span{
              word-break: word-all;
            }
          }
        }
  
      }
      .cb-mobile-local-panels { 
        z-index: 905;
      }

      .cb-menu-panel {
        position: fixed;
        right: auto;
        transition: width .3s;
        opacity: .96;
				li {
					display: none;
					&.cb-mobile-panel-title {
						padding: 12px 24px 8px 0;
						border-bottom: 4px solid @black-corporate-color;
						color: @black-corporate-color;
						.roboto-bold();
					}
					a {
            padding: 12px 0 0 0!important;
					}
        }
        &.show {
          min-width: auto;
          li {
            display: block!important;
            &.cb-nav-back {
              height: 48px;
              border-bottom: 0;
              
              a:not(.local-menu-icon) {
                padding-left: 18px!important;
                display: inline-block;
                .roboto();
                &::before {
                  .cb-glyphs();
                  left: 22px;
                  position: absolute;
                  top: 16px;
                  content: "\e921";
                }
              }
            }

            a {
              &.local-menu-icon{
                float: right;
                padding-right: 0!important;
              }
            }
            &.cb-close-home {
              a.local-menu-icon{
                float: right;
              }
            }
          }
          .link-panel {
            padding: 0 24px;
          }
        }
      }
    }

    // Push Down Menu
    z-index: 902;
    transition: all .2s;

    .cb-slidedown-items {
    
      background: -webkit-linear-gradient(top, #f0f0f0 0, #fff 24px, #fff);
      // mobile/tablet
      @media (max-width: @screen-sm-max) {
        display: none;
      }
    
      // ---------- slidedown Navigation
      .cb-slidedown-container {
        height: 0px;
        position: relative;
        width: 100%;
        overflow-x: hidden!important;
        &.transition {
          /* easeInOutQuad */
          transition: height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); 
        }			
        .cb-slidedown-inner {
          position: absolute;
          bottom: 0;
    
          // pushdown
          &.cb-slidedown-pushdown {
            padding: 24px 0;
            ul {
              &.cb-pushdown-list {
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: -24px;
                li {
                  margin: 0;
                  padding: 0;
                  // white-space: nowrap;
                  // flex: 1 1;
                  margin: 24px;
                  a{
                    &:focus,
                    &:hover,
                    &.focus {
                      text-decoration: underline;
                    }
                  }
                }
              }
      
            }
          }
    
          // Mega Menu
          &.cb-slidedown-megamenu {
            width: 100%;
            .cb-masonry-grid {
              margin-top: 24px;
              .cb-menu-list {
                ul {
                  li {
                    > h3, > .h3,
                    > h4, > .h4 {
                      font-size: 1rem;
                      line-height: 1.5em;
                    }
                    a {
                      font-size: 0.875em;
                      line-height: 1.7142857142857142em
                    }
                  }
                }
              }
            }
          }
        }
      }
    } 
  }
}
