//
// On-Page Menu
// Focus: custom
// --------------------------------------------------

.cb-onpage-menu {
  .cb-onpage-menu-desktop {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 0;
        padding: 0;
        margin-right: 48px;
        white-space: nowrap;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    a {
      color: @black-corporate-color;
      display: block;
      font-size: 0.875em;
      line-height: 1.7142857142857142em;
      padding-bottom: 10px;
      border-bottom: 2px solid @white-color;
      transition: border-bottom .25s ease-in-out;
      &.active,
      &:hover,
      &:focus {
        outline: 0;
        color: @black-corporate-color;
        border-bottom: 2px solid @black-corporate-color;
        text-decoration: none;
      }
    }
  }
}